import React from 'react';

import { Typography } from '@mui/material';

import { ApplicationFormInfoBlock, Paragraph } from '@pages/ApplicationForm/containers/ApplicationFormHints/styles';

function ApplicationFormHints() {
	return (
		<ApplicationFormInfoBlock>
			<Typography variant={'h5'} mb={2}>
				<b>How to Navigate:</b>
			</Typography>
			<Paragraph>
				The application form is broken into sections that include Page 1 – which contains all account information and
				disclosure forms – and Signatures and Documents. The application can be completed by either selecting the next
				section towards the bottom of each question page, or by selecting each section in any desired order from the
				left side Application form menu.
			</Paragraph>
			<Paragraph>
				Once a section is completed, the number associated with the question page will turn green to indicate that all
				required fields have been answered. If the application is submitted before all required fields are submitted,
				the necessary sections will turn red.
			</Paragraph>
			<Paragraph>
				An application can be saved at any time to be completed at a later date. However, all required fields in an
				application must be complete for an application to be submitted successfully.
			</Paragraph>
			<Paragraph sx={{ mb: 4 }}>
				To change an answer, select the delete icon next to the question or select another answer from the choices
				provided.
			</Paragraph>
			{/*<Typography variant={'h5'} mb={2}>*/}
			{/*	<b>Questions and Definitions:</b>*/}
			{/*</Typography>*/}
			{/*<Typography variant={'h6'} mb={2}>*/}
			{/*	Application Type:*/}
			{/*</Typography>*/}
			{/*<ul style={{ marginBottom: 32 }}>*/}
			{/*	<li style={{ marginBottom: 16 }}>*/}
			{/*		<b>Referral Code:</b> Referral codes are a unique combination of numbers and/of letters used by companies as*/}
			{/*		part of a customer referral program. This is the only field not required on the Application Type page.*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<b>Customer Type:</b> There are four different types of brokerage accounts that can be opened through*/}
			{/*		ViewTrade.*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>Individual</b> accounts allow a sole investor to buy and sell securities with cash deposited. This type*/}
			{/*				of account may be favorable for those with short-term savings goals.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>Joint</b> accounts operate like individual accounts but provide the opportunity to hold investments*/}
			{/*				with another person. This account type may be beneficial for estate planning or other family investments.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>Custodian</b> accounts are savings accounts managed by an adult on behalf of a minor, making them ideal*/}
			{/*				for college funds. Control of the account passes to the minor once they reach the legal age of maturity in*/}
			{/*				their jurisdiction of residence.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>IRA accounts</b>, or Individual Retirement Accounts, provide tax advantage for long-term retirement*/}
			{/*				investments as contributions are typically tax-deductible. Account holders can contribute up to the*/}
			{/*				maximum amount allowed by the Internal Revenue Service annually.*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li style={{ marginBottom: 16 }}>*/}
			{/*		<b>Account Type:</b> There are two different account types for brokerage accounts.*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>Cash</b> accounts require all transactions to be made with available cash funded by a connected*/}
			{/*				financial institution. These accounts can be beneficial to novice investors as it limits potential losses*/}
			{/*				to the total amount invested.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>Margin</b> accounts allow for investments to be made from money borrowed against the value of the*/}
			{/*				securities held by the account. These types of accounts increase purchasing power, but also can expose*/}
			{/*				your portfolio to more risk.*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li style={{ marginBottom: 16 }}>*/}
			{/*		<b>Options Trading:</b> allows for the buying and selling of securities through options contracts, providing*/}
			{/*		you the ability to speculate on the future direction of a security by agreeing to trade at a set price by a*/}
			{/*		specific date. Options can be a useful investment strategy for advanced investors who seek to gain higher*/}
			{/*		returns while limiting risk.*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				If the <b>Yes</b> option is selected, the account holder is required to choose which option level for*/}
			{/*				their account.*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						<b>Level 1: </b>provides basic price quotes data for a security, including the best bid and ask price*/}
			{/*						alongside the size of the orders at those prices.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						<b>Level 2: </b>shows all basic price quotes provided in Level 1, as well as all other orders placed*/}
			{/*						at prices above or below the best bid or ask price.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*</ul>*/}
			{/*<Typography variant={'h5'} mb={2}>*/}
			{/*	<b>Individual Account Type:</b>*/}
			{/*</Typography>*/}
			{/*<ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Identity Page (Primary Applicant):*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			The applicant’s First and Last Name are required, as well as Date of Birth. Those opening an individual*/}
			{/*			account must be the legal age of maturity in their jurisdiction of residence.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Citizenship Page (Primary):*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>*/}
			{/*			If you are a citizen of the United States, provide your nine-digit Social Security or Tax Identification*/}
			{/*			Number. If you are not a U.S. citizen, select your country of origin.*/}
			{/*		</li>*/}
			{/*		<li>For residents of the U.S., provide your nine-digit Social Security or Tax Identification Number.</li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			For non-U.S. residents, provide your Visa Type and its Expiration Date, as well as your nine-digit Social*/}
			{/*			Security or Tax ID number. If you do not have a Visa, provide your Birth Country and your corresponding*/}
			{/*			National ID number.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Contact Page (Primary):*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>*/}
			{/*			If you are a resident of the United States, provide your complete home address in the required fields.{' '}*/}
			{/*		</li>*/}
			{/*		<li>If you are not a resident, please provide your country or residence alongside your address. </li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			If you have a mailing address separate from home address, you will be prompted to provide that address in*/}
			{/*			the next section.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Mailing Address (Primary):*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			Provide your complete address if you are a United States resident. If not, provide your country of residence*/}
			{/*			alongside your address.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Disclosures Page:*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>*/}
			{/*			Currently only domestic accounts are available to open through ViewTrade, meaning investments can only be*/}
			{/*			made for U.S. securities.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			All political questions are in ordinance with U.S. federal laws that seek to limit public trading on*/}
			{/*			private, or insider knowledge.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			Indicate whether you are connected to the introducing brokerage that will facilitate transactions from your*/}
			{/*			account.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			If you are connected to a member firm of a Stock Exchange or a Financial Industry Regulatory Authority,*/}
			{/*			disclose the firm you are affiliated with.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			Indicate if you are 65 years or older. This question is required under U.S. securities laws that protect*/}
			{/*			older investors from suspected fraud or financial exploitation.*/}
			{/*		</li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			A trusted contact is a person you authorize your financial firm to contact under limited circumstances. A*/}
			{/*			trusted contact may be asked to confirm the account holder’s current contact information, health status, or*/}
			{/*			the identity of any legal guardian, executor, trustee or holder of a power of attorney. If you would like to*/}
			{/*			add a trusted contact, you will be prompted to provide their information on the next section.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Trusted Contact Page:*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			Please provide the trusted contact’s complete contact information and agree to the FINRA Rule 4512 trusted*/}
			{/*			contact disclosure at the bottom of the page. If the trusted contact is not a resident of the U.S., provide*/}
			{/*			their country of residence.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Employment Page (Primary)-*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>If you are employed, either part- or full-time, provide the name of your employer and position title.</li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			If you are unemployed, provide your primary source of income. There are no government restrictions on*/}
			{/*			investing while receiving unemployment benefits. Students and Retirees will not be asked to provide income*/}
			{/*			information.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Investment Profile Page:*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>*/}
			{/*			Your account’s investment objective is a set of goals that determines how you plan to conduct your financial*/}
			{/*			portfolio.*/}
			{/*			<ul style={{ marginBottom: 16 }}>*/}
			{/*				<li>*/}
			{/*					<b>Capital Preservation:</b> This type of portfolio aims to preserve current capital and prevents loss*/}
			{/*					in a portfolio. This strategy is best for the safest short-term securities and to maintain a current*/}
			{/*					savings amount.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Income:</b> This type of portfolio seeks to maximize the amount of income the account is able to*/}
			{/*					withdraw given its risk tolerance.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Growth:</b> This type of portfolio achieves high long-term growth and capital appreciation given its*/}
			{/*					risk tolerance.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Speculation:</b> This type of investment strategy has the goal of making faster profits by taking*/}
			{/*					advantage of price fluctuation in the market. This can be a risky strategy with the potential for higher*/}
			{/*					losses, so it is more suitable for investors with high risk tolerance.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Growth Income:</b> This type of portfolio seeks to maximize the amount of income given its risk*/}
			{/*					tolerance. This strategy is best for those looking to invest in securities that pay out dividends.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Maximum Growth:</b> This investment strategy seeks to maximize growth, meaning the account is willing*/}
			{/*					to take on more risk for the potential of more gains.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Balanced:</b> This type of portfolio seeks to maintain a balance of securities by investing 50% in*/}
			{/*					growth assets like equities and 50% in defensive assets like cash.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			You can determine your liquid net worth by taking the total sum of your financial liabilities and*/}
			{/*			subtracting that from the total sum of your liquid assets.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			Your net worth is your assets minus your financial liabilities, meaning all the capital left over after you*/}
			{/*			pay all necessary debt and expenses.*/}
			{/*		</li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			Total assets include your investment, savings, cash deposits, and any equity that you have in a home, car or*/}
			{/*			other similar assets.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			<b>Risk tolerance:</b>*/}
			{/*			<ul>*/}
			{/*				<li>Low - or conservative risk tolerance favors investments that maintain original capital value</li>*/}
			{/*				<li>*/}
			{/*					Medium - or moderate risk tolerance values reducing risks and enhancing returns equally. The account is*/}
			{/*					willing to accept some risk of losses to seek higher long-term returns.*/}
			{/*				</li>*/}
			{/*				<li style={{ marginBottom: 16 }}>*/}
			{/*					High - or aggressive risk tolerance values the potential of high rewards at the cost of possibly high*/}
			{/*					losses. This type of strategy is best for more seasoned investors that have a good understanding of*/}
			{/*					fluctuating in the stock market.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			<b>Liquidity needs:</b> The value of assets you need to be able to easily access or convert to cash.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			<b>Time Horizon</b> is the expected length of the investment upon funding.*/}
			{/*		</li>*/}
			{/*		<li>*/}
			{/*			<b>Experience:</b> investment experience comes from learning how the stock market operates through direct*/}
			{/*			participation in events or investment opportunities. Those with None or Limited knowledge are just starting*/}
			{/*			to invest, while those with Good or Extensive knowledge already have a good foundation in investment markets*/}
			{/*			and higher skills due to their experience.*/}
			{/*		</li>*/}
			{/*		<li style={{ marginBottom: 16 }}>*/}
			{/*			<b>Tax Bracket:</b> Calculate your federal tax bracket by dividing your total tax owed (line 16) on Form*/}
			{/*			1040 by your total taxable income (line 15). The income thresholds for tax brackets are updated annually by*/}
			{/*			the federal government.*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Service Profile Page:*/}
			{/*		</Typography>*/}
			{/*	</li>*/}
			{/*	<ul>*/}
			{/*		<li>*/}
			{/*			This page outlines what services you would like to receive while investing through a ViewTrade account.*/}
			{/*			<ul>*/}
			{/*				<li>*/}
			{/*					<b>Sweep Account:</b> A sweep account automatically transfers investment gains that cross a certain*/}
			{/*					threshold into interest earnings securities at the end of each business day.*/}
			{/*					<ul>*/}
			{/*						<li>*/}
			{/*							If you agree to enter the Sweep Program, indicate to hold sold proceeds – meaning held within the*/}
			{/*							Sweep account – or send the sold proceeds to your connected banking account.*/}
			{/*						</li>*/}
			{/*					</ul>*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Dividend Reinvestment:</b> With dividend reinvest, your account will buy more shares in a security*/}
			{/*					that pays out dividends when the dividend is paid, which helps compound investment gains.*/}
			{/*					<ul>*/}
			{/*						<li style={{ marginBottom: 16 }}>*/}
			{/*							If you choose to not reinvest dividends, indicate whether the proceeds should be held by your*/}
			{/*							account or sent to your connected banking account.*/}
			{/*						</li>*/}
			{/*					</ul>*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</li>*/}
			{/*	</ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			W9 Certification:*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				The Request for Taxpayer Identification Number and Certification is required by the Internal Revenue*/}
			{/*				Service to report contributions made to an IRA, dividends, stock and broker transactions, and other*/}
			{/*				miscellaneous income.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>Exempt Payee:</b> a company for whom backup withholding is not necessary, even in circumstances where*/}
			{/*				this would typically be required. Individuals are usually not exempt.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>FATCA Exemption:</b> Those exempt from FATCA typically have a beneficial holding a foreign trust or a*/}
			{/*				foreign estate.*/}
			{/*			</li>*/}
			{/*			<li style={{ marginBottom: 16 }}>Provide codes if any apply for either of these W9 Certifications.</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Transfer on Death Form:*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				For domestic accounts, you have the option to transfer your investment funds to up to five beneficiaries*/}
			{/*				upon your death.*/}
			{/*			</li>*/}
			{/*			<ul>*/}
			{/*				<li>*/}
			{/*					Your spouse’s signature is required if you are married. Provide their full name to serve as their legal*/}
			{/*					signature after agreeing to the Transfer on Death disclosure.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>A Primary Beneficiary</b> is the person first in line to receive your account’s investment funds.*/}
			{/*					This role is typically for your spouse or child. You may name more than one primary beneficiary and*/}
			{/*					designate each percentage of assets each would receive.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>A Contingent Beneficiary</b> serves as a back-up to a primary beneficiary. That means if all the*/}
			{/*					primary beneficiaries have passed away upon the account holder’s death, the contingent beneficiary will*/}
			{/*					receive the percentage of assets granted to them.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					If your beneficiary is a resident of the United States, provide your complete home address in the*/}
			{/*					required fields.*/}
			{/*				</li>*/}
			{/*				<li style={{ marginBottom: 16 }}>*/}
			{/*					If your beneficiary is not a resident, please provide your country or residence alongside your address.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Signatures and Documents*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>For Legal Disclosures,</b> all documents must be read thoroughly before the “I agree” select will be*/}
			{/*				accessible. Each disclosure must be agreed upon before the application is submitted for the submission to*/}
			{/*				be successful.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>Consolidated Audit Trail Account Holder Type:</b>*/}
			{/*			</li>*/}
			{/*			<ul>*/}
			{/*				<li>*/}
			{/*					The Consolidated Audit Trail tracks orders and identifies the broker-dealer that is handling them. This*/}
			{/*					allows regulators to track eligible securities activity in U.S. markets.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Employee Account:</b> If you are an employee of a FINRA, select this option.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>Individual Customer:</b> If you work or operate outside of a broker-dealer firm, select this option.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*			<li>*/}
			{/*				<b>For Signatures,</b> provide your full legal name. This will act as a legal signature.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>For Documents,</b>*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						A 407 letter is required for FINRA registered representatives that allows the employee to hold*/}
			{/*						investments in equities or bonds in personal accounts.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						A 3210 Letter refers to the written permission given by an employer to an employee to hold*/}
			{/*						investments.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*</ul>*/}
			{/*<Typography variant={'h5'} mb={2}>*/}
			{/*	<b>Joint Account Type: </b>*/}
			{/*</Typography>*/}
			{/*<ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Joint Account Type (Page 1)*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>Joint Survivor Type:</b>*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						<b>Tenants in Common:</b> is the equal or unequal undivided ownership of an account between two or*/}
			{/*						more people. There can be up to three participants, each having an individually set percentage of the*/}
			{/*						investments.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						<b>Joint with Rights of Survivorship:</b> allows for two or more owners for an account. Each owner has*/}
			{/*						an equal right to the account’s assets and is afforded survivorship rights if the other account holder*/}
			{/*						dies.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						<b>Joint Tenants with Entirety:</b> can only be opened by married couples, and permits spouses to*/}
			{/*						jointly own assets as a single legal entity. This means that each spouse has an equal and undivided*/}
			{/*						interest in the account.*/}
			{/*					</li>*/}
			{/*					<li style={{ marginBottom: 16 }}>*/}
			{/*						<b>Joint Community Property:</b> can only be owned only by a married couple. Under this joint account,*/}
			{/*						assets are split equally between each spouse, and, if one owner dies, their share will go to the*/}
			{/*						couple’s estate. This type of account is only available in the states of AZ, CA, ID, LA, NM, NV, TX,*/}
			{/*						WA, and WI.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Joint Applicant*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				Identity*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						This is for the other primary owner of the joint account. The applicant’s First and Last Name are*/}
			{/*						required, as well as Date of Birth. Those opening an account must be the legal age of maturity in*/}
			{/*						their jurisdiction of residence.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				Citizenship*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						If you are a citizen of the United States, provide your nine-digit Social Security or Tax*/}
			{/*						Identification Number. If you are not a U.S. citizen, select your country of citizenship and your*/}
			{/*						National ID number.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						For residents of the U.S., provide your nine-digit Social Security or Tax Identification Number.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						For non-U.S. residents, provide your Visa Type and its Expiration Date, as well as your nine-digit*/}
			{/*						Social Security or Tax ID number. If you do not have a Visa, provide your Birth Country.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				Account Information*/}
			{/*				<ul>*/}
			{/*					<li>Provide the joint applicant’s address if it differs from the primary applicant’s address.</li>*/}
			{/*					<li>*/}
			{/*						A phone number and email address is required for the second applicant to be used to contact regarding*/}
			{/*						the account if the primary account holder is unavailable.*/}
			{/*					</li>*/}
			{/*					<li>Provide a separate mailing address if applicable.</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				Employment*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						If you are employed, either part- or full-time, provide the name of your employer and position title.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						If you are unemployed, provide your primary source of income. There are no government restrictions on*/}
			{/*						investing while receiving unemployment benefits.*/}
			{/*					</li>*/}
			{/*					<li style={{ marginBottom: 16 }}>*/}
			{/*						Students and Retirees will not be asked to provide income information.*/}
			{/*					</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Signatures and Documents*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>For Legal Disclosures</b>, all documents must be read thoroughly before the “I agree” select will be*/}
			{/*				accessible. Each disclosure must be agreed upon before the application is submitted for the submission to*/}
			{/*				be successful.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>For Signatures</b>, provide your full legal name. This will act as a legal signature.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				For <b>Documents</b>,*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						A 407 letter is required for FINRA registered representatives that allows the employee to hold*/}
			{/*						investments in equities or bonds in personal accounts. This requires the disclosure of personal bank*/}
			{/*						account information for the account in which securities are held.*/}
			{/*					</li>*/}
			{/*					<li>*/}
			{/*						A 3210 Letter refers to the written permission given by an employer to an employee to hold*/}
			{/*						investments. This is for accounts opened by members at firms other than where they work.*/}
			{/*					</li>*/}
			{/*					<li>Joint applications require Photo ID information from both parties.</li>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*</ul>*/}
			{/*<Typography variant={'h5'} mb={2}>*/}
			{/*	<b>Custodian Account Type:</b>*/}
			{/*</Typography>*/}
			{/*<ul>*/}
			{/*	<li>Custodian accounts are only supported domestically in the United States.</li>*/}
			{/*</ul>*/}
			{/*<ul>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Custodian Type:*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>UTMA</b>, or uniform transfer to minors, allows a minor to receive gifts without the aid of a guardian*/}
			{/*				or trustee.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				<b>UGMA</b> or the Uniform Gifts to Minors Act allows money and financial securities to be transferred to*/}
			{/*				minors through a UGMA account.*/}
			{/*			</li>*/}
			{/*			<li style={{ marginBottom: 16 }}>*/}
			{/*				Transfer of assets are not permitted in a UGMA account. The main difference between UTMA and UGMA*/}
			{/*				custodial accounts is the set-up of the account – UGMA contains only financial products such as stocks,*/}
			{/*				bonds and mutual fonts, while UTMA can have both financial and physical assets. Once the minor reaches the*/}
			{/*				age of majority, the custodian account ends with the beneficiary becoming the owner of the account.*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Custodian Information:*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>Provide all required information for the custodian, or owner, of the investment account.</li>*/}
			{/*			<li>*/}
			{/*				The applicant’s First and Last Name are required, as well as Date of Birth. Those opening an individual*/}
			{/*				account must be the legal age of maturity in their jurisdiction of residence.*/}
			{/*			</li>*/}
			{/*			<li>*/}
			{/*				If you are a resident of the United States, provide your complete home address in the required fields.*/}
			{/*			</li>*/}
			{/*			<li>If you are not a resident, please provide your country or residence alongside your address.</li>*/}
			{/*			<li>*/}
			{/*				If you have a mailing address separate from home address, you will be prompted to provide that address in*/}
			{/*				the next section.*/}
			{/*			</li>*/}
			{/*			<li>Provide your nine-digit Social Security number.</li>*/}
			{/*			<li>Provide your phone and email for contact purposes regarding the account.</li>*/}
			{/*			<li>*/}
			{/*				Provide your employment status. If you are employed, either part- or full-time, provide the name of your*/}
			{/*				employer and position title.*/}
			{/*			</li>*/}
			{/*			<li style={{ marginBottom: 16 }}>*/}
			{/*				If you are unemployed, provide your primary source of income. There are no government restrictions on*/}
			{/*				investing while receiving unemployment benefits. Students and Retirees will not be asked to provide income*/}
			{/*				information.*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			IRA Account Type*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				<b>IRA Type:</b> IRA, or Individual Retirement Account, can only be a domestic cash account with no*/}
			{/*				options. If you select to open a margin account under an IRA account type, your application will be*/}
			{/*				rejected. Please select <b>Cash</b> when asked to choose between cash or margin and <b>No</b> to the*/}
			{/*				Options question.*/}
			{/*				<ul>*/}
			{/*					<li>*/}
			{/*						<b>Type of IRA account:</b>*/}
			{/*					</li>*/}
			{/*					<ul>*/}
			{/*						<li>*/}
			{/*							<b>Traditional IRA:</b> This type of individual retirement account that lets your earnings grow*/}
			{/*							tax-deferred. That means you pay taxes on your investment gains only when you make a withdrawal.*/}
			{/*							Withdrawals are considered taxable income at whatever tax bracket you fall under when you retire.*/}
			{/*						</li>*/}
			{/*						<li>*/}
			{/*							<b>Roth IRA:</b> This type of account offers tax-free growth and tax-free withdrawals in retirement.*/}
			{/*							While there are no current-year tax benefits, your contributions and earnings can grow tax-free, and*/}
			{/*							you can withdraw them tax and penalty free after age 59 1/2 and once the account has been open for*/}
			{/*							five years.*/}
			{/*						</li>*/}
			{/*						<li>*/}
			{/*							<b>SEP:</b> Simplified Employee Pension plans are a tax-deferred pension plan for companies that*/}
			{/*							have no more than 25 employees or self-employed individuals.*/}
			{/*						</li>*/}
			{/*						<li>*/}
			{/*							<b>Rollover IRA:</b> This type of account is used to move money from employer-sponsored retirement*/}
			{/*							plans into a new IRA.*/}
			{/*						</li>*/}
			{/*						<li>*/}
			{/*							<b>Simple IRA:</b> Savings Incentive Match Plan for Employees (SIMPLE) IRA allows both employees and*/}
			{/*							employers to contribute to traditional IRAs benefitting employees.*/}
			{/*						</li>*/}
			{/*						<li style={{ marginBottom: 16 }}>*/}
			{/*							<b>Beneficiary Traditional IRA:</b> This type allows any person or eternity the account owner*/}
			{/*							chooses to receive the benefits of the IRA upon their death. The account owner must provide the name*/}
			{/*							of the beneficiary.*/}
			{/*						</li>*/}
			{/*					</ul>*/}
			{/*				</ul>*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			Disclosures*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>If you are granting trading authorization to another party:</li>*/}
			{/*			<ul>*/}
			{/*				<li>*/}
			{/*					Trading Authorization allows the account holder to give a third party access to their account for the*/}
			{/*					purpose of trading on their behalf. The third party is typically a financial professional or a financial*/}
			{/*					advisory service.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*	<li>*/}
			{/*		<Typography variant={'h6'} mb={2}>*/}
			{/*			IRA Beneficiaries*/}
			{/*		</Typography>*/}
			{/*		<ul>*/}
			{/*			<li>*/}
			{/*				If you never file a beneficiary designation, your benefits will be paid according to legal procedure at*/}
			{/*				the time of your death. These forms are the instructions for the custodian of the IRA on how and to whom*/}
			{/*				the funds will be distributed.*/}
			{/*			</li>*/}
			{/*			<ul>*/}
			{/*				<li>*/}
			{/*					If you would like to complete the IRA Beneficiaries form, you will need to name at least one primary*/}
			{/*					beneficiary.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>A Primary Beneficiary</b> is the person first in line to receive your account’s investment funds.*/}
			{/*					This role is typically for your spouse or child. You may name more than one primary beneficiary and*/}
			{/*					designate each percentage of assets each would receive.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					<b>A Contingent Beneficiary</b> serves as a back-up to a primary beneficiary. That means if all the*/}
			{/*					primary beneficiaries have passed away upon the account holder’s death, the contingent beneficiary will*/}
			{/*					receive the percentage of assets granted to them.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					If your beneficiary is a resident of the United States, provide your complete home address in the*/}
			{/*					required fields.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					If your beneficiary is not a resident, please provide your country or residence alongside your address.*/}
			{/*				</li>*/}
			{/*				<li>*/}
			{/*					Select <b>No</b> if you would like to designate less than five beneficiaries.*/}
			{/*				</li>*/}
			{/*			</ul>*/}
			{/*		</ul>*/}
			{/*	</li>*/}
			{/*</ul>*/}
		</ApplicationFormInfoBlock>
	);
}

export default ApplicationFormHints;
